import { Injectable, OnDestroy, Inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { get } from 'lodash'

import { Subscription, Observable, combineLatest } from 'rxjs'
import { AppStateWithUser } from '../../../_core/root-store/user/user.reducer'
import { selectPermissionsData } from '../../../_core/root-store/user/user.selectors'
import {
  AVAILABLE_FLEET_ROLES,
  FleetRolesType,
} from './config/rmd-permissions.config'
import { FleetRole } from '../../../_core/models/fleet-customer.models'
import { filter, map, shareReplay } from 'rxjs/operators'
import { RmdFeaturesService } from '../rmd-features/rmd-features.service'

// Must be provided in root module to be a singleton service
// If we provide RmdPermissionsService in RmdPermissionsModule,
// new instance of RmdPermissionsService service will be created on every import of RmdPermissionsModule
@Injectable({ providedIn: 'root' })
export class RmdPermissionsService implements OnDestroy {
  private subscription: Subscription
  private fleetRole: FleetRole
  private isFuelConsultant: boolean

  private fleetRole$: Observable<{
    fleetRole: FleetRole
    isFuelConsultant: boolean
  }> = this.store.select(selectPermissionsData)

  public canSeeCosts$: Observable<boolean> = combineLatest([
    this.hasRoleAsObservable('FUEL_CONSULTANT'),
    this.hasRoleAsObservable([
      'FLEET_COMPANY_ADMIN',
      'FLEET_COMPANY_MEMBER',
      'FLEET_ACCOUNT_ADMIN',
      'FLEET_ACCOUNT_MEMBER',
    ]),
    this.featuresService.hasFeatureAsObservable('RMD_PRICING'),
  ]).pipe(
    filter(
      ([isFuelConsultant, isAccountOrCompanyAdmin, hasPricingFeature]) =>
        isFuelConsultant !== null &&
        isAccountOrCompanyAdmin !== null &&
        hasPricingFeature !== null
    ),
    map(
      ([isFuelConsultant, isAccountOrCompanyAdmin, hasPricingFeature]) =>
        isFuelConsultant || (isAccountOrCompanyAdmin && hasPricingFeature)
    ),
    shareReplay(1)
  )

  constructor(
    private store: Store<AppStateWithUser>,
    private featuresService: RmdFeaturesService,
    @Inject(AVAILABLE_FLEET_ROLES) private readonly FLEET_ROLES: FleetRole[]
  ) {
    this.subscription = this.fleetRole$.subscribe(
      ({ fleetRole, isFuelConsultant }) => {
        this.fleetRole = fleetRole
        this.isFuelConsultant = isFuelConsultant
      }
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public hasRoleAsObservable(
    role: FleetRolesType | FleetRolesType[]
  ): Observable<boolean> {
    return this.fleetRole$.pipe(
      map(({ isFuelConsultant, fleetRole }) => {
        if (fleetRole === null) {
          return null
        }
        let hasRole = false
        const fleetRoleName = get(fleetRole, 'name')
        if (typeof role === 'string') {
          if (role === 'FUEL_CONSULTANT') {
            hasRole = !!this.isFuelConsultant
          } else {
            hasRole = fleetRoleName === role
          }
        } else {
          if (role.includes('FUEL_CONSULTANT')) {
            hasRole = role.includes(fleetRoleName) || isFuelConsultant
          } else {
            hasRole = role.includes(fleetRoleName)
          }
        }
        return hasRole
      })
    )
  }

  public hasRole(role: FleetRolesType | FleetRolesType[]): boolean {
    if (typeof role === 'string') {
      if (role === 'FUEL_CONSULTANT') {
        return this.isFuelConsultant
      }
      return get(this, 'fleetRole.name', '') === role
    } else {
      const fleetRoleName = get(this, 'fleetRole.name')

      if (role.includes('FUEL_CONSULTANT')) {
        return role.includes(fleetRoleName) || this.isFuelConsultant
      }
      return role.includes(fleetRoleName)
    }
  }

  public getFleetRole(): FleetRole {
    return this.fleetRole
  }

  public getIsFuelConsultant(): boolean {
    return this.isFuelConsultant
  }

  public hasPermission(permission: string): boolean {
    if (this.isFuelConsultant) {
      return true
    }

    const fleetRole = this.FLEET_ROLES.find(
      (fr) => fr.name === get(this, 'fleetRole.name', '')
    )
    return get(fleetRole, 'permissions', []).includes(permission)
  }
}
