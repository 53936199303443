import { InjectionToken } from '@angular/core'
import { FleetRole } from '../../../../_core/models/fleet-customer.models'

export type FleetRolesType =
  | 'FLEET_COMPANY_ADMIN'
  | 'FLEET_COMPANY_MEMBER'
  | 'FLEET_ACCOUNT_ADMIN'
  | 'FLEET_ACCOUNT_MEMBER'
  | 'FLEET_SUB_ACCOUNT'
  | 'FUEL_CONSULTANT'

export enum FleetRoles {
  FLEET_COMPANY_ADMIN = 'FLEET_COMPANY_ADMIN',
  FLEET_COMPANY_MEMBER = 'FLEET_COMPANY_MEMBER',
  FLEET_ACCOUNT_ADMIN = 'FLEET_ACCOUNT_ADMIN',
  FLEET_ACCOUNT_MEMBER = 'FLEET_ACCOUNT_MEMBER',
  FUEL_CONSULTANT = 'FUEL_CONSULTANT',
  FLEET_SUB_ACCOUNT = 'FLEET_SUB_ACCOUNT',
}

export enum FleetPermissions {
  CAN_SEE_MIN_ORDER_DATA = 'CAN_SEE_MIN_ORDER_DATA',
  CAN_SEE_COSTS = 'CAN_SEE_COSTS',
}

export const AVAILABLE_FLEET_ROLES = new InjectionToken<FleetRole[]>(
  'available fleet roles'
)

export const FLEET_ROLES: FleetRole[] = [
  {
    name: FleetRoles.FLEET_COMPANY_ADMIN,
    permissions: [
      FleetPermissions.CAN_SEE_COSTS,
      FleetPermissions.CAN_SEE_MIN_ORDER_DATA,
    ],
  },
  {
    name: FleetRoles.FLEET_COMPANY_MEMBER,
    permissions: [
      FleetPermissions.CAN_SEE_COSTS,
      FleetPermissions.CAN_SEE_MIN_ORDER_DATA,
    ],
  },
  {
    name: FleetRoles.FLEET_ACCOUNT_ADMIN,
    permissions: [
      FleetPermissions.CAN_SEE_COSTS,
      FleetPermissions.CAN_SEE_MIN_ORDER_DATA,
    ],
  },
  {
    name: FleetRoles.FLEET_ACCOUNT_MEMBER,
    permissions: [
      FleetPermissions.CAN_SEE_COSTS,
      FleetPermissions.CAN_SEE_MIN_ORDER_DATA,
    ],
  },
  {
    name: FleetRoles.FLEET_SUB_ACCOUNT,
    permissions: [FleetPermissions.CAN_SEE_MIN_ORDER_DATA],
  },
]
