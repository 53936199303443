import { Store } from '@ngrx/store'
import { Injectable, OnDestroy } from '@angular/core'
import { Subject, Observable } from 'rxjs'

import { AppStateWithUser } from '../../../_core/root-store/user/user.reducer'
import { selectedFeatureFlags } from '../../../_core/root-store/user/user.selectors'
import { filter, map, takeUntil } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class RmdFeaturesService implements OnDestroy {
  private featureFlags: string[] = []
  private isFuelConsultant = false
  private featureFlagsData$: Observable<{
    featureFlags: string[]
    isFuelConsultant: boolean
  }>

  private _destroy = new Subject<void>()

  constructor(private store: Store<AppStateWithUser>) {
    this.featureFlagsData$ = this.store.select(selectedFeatureFlags)
    this.featureFlagsData$
      .pipe(
        filter((featureFlagsData) => !!featureFlagsData),
        map((combined) => {
          const { featureFlags, isFuelConsultant } = combined
          return { featureFlags, isFuelConsultant }
        }),
        takeUntil(this._destroy)
      )
      .subscribe(({ featureFlags, isFuelConsultant }) => {
        this.featureFlags = featureFlags
        this.isFuelConsultant = isFuelConsultant
      })
  }

  ngOnDestroy(): void {
    this._destroy.next()
    this._destroy.complete()
  }

  public hasFeature(feature: string): boolean {
    if (this.isFuelConsultant) {
      return true
    }

    return (this.featureFlags || []).includes(feature)
  }

  public hasFeatureAsObservable(feature: string): Observable<boolean> {
    return this.featureFlagsData$.pipe(
      filter((data) => data !== null),
      map((featureFlagsData) => {
        const { featureFlags = [], isFuelConsultant } = featureFlagsData
        if (isFuelConsultant) {
          return true
        }

        return featureFlags.includes(feature)
      })
    )
  }
}
